import API from './api'

export default class Forms {
  constructor() {
    this.form = document.querySelector('form')

    if (this.form) {
      this.initialize()
    }
  }

  initialize() {
    switch (this.form.id) {
      case 'caminota-step-1':
        console.log('caminota-step-1')
        break

      case 'caminota-step-2':
        this.initNumberinputs(this.patchGrosime.bind(this), 1)
        break

      case 'caminota-step-3':
        this.details = document.querySelector('#form_order_details')
        this.initNumberinputs(this.patchQuantity.bind(this), 0)
        break

      default:
        break
    }
  }

  // initInputGrosime(key) {
  //   const input = this.form[key]
  //   const updateElement = document.querySelector('#response_' + key)

  //   if (input)
  //     input.addEventListener('change', (event) => {
  //       this.patchGrosime(key, event.target)
  //         .then((response) => {
  //           input.closest('.field').classList.remove('has-error')

  //           if (response && updateElement)
  //             updateElement.innerHTML = response.text
  //         })
  //         .catch((e) => {
  //           input.closest('.field').classList.add('has-error')
  //         })
  //     })
  // }

  patchGrosime(key, input) {
    return API.patch(
      '/api/caminota-form/' + this.form.dataset.slug + '/update_grosime/',
      {
        body: JSON.stringify({
          [key]: parseFloat(input.value),
        }),
      }
    )
      .then((response) => {
        if (response == null) return

        input.closest('.field').classList.remove('has-error')

        const update = document.querySelector(
          `[data-name="${input.dataset.updates}"]`
        )

        if (update && response.text) {
          update.innerHTML = response.text
        }
      })
      .catch((e) => {
        input.closest('.field').classList.add('has-error')
      })
  }

  initNumberinputs(callback, zeros = 0) {
    if (callback == null) return
    const fields = document.querySelectorAll('[data-component="numberinput"]')

    fields.forEach((field) => {
      const input = field.querySelector('input')
      const name = input.getAttribute('name')

      input.addEventListener('change', () => {
        callback(name, input)
      })

      field.addEventListener('click', (event) => {
        if (event.target.classList.contains('is-plus')) {
          this.updateInput(callback, name, input, event.target, zeros, 1)
        } else if (event.target.classList.contains('is-minus')) {
          this.updateInput(callback, name, input, event.target, zeros, -1)
        }
      })
    })
  }

  async updateInput(callback, name, input, button, zeros, sign) {
    if (button) button.classList.add('is-loading')

    const value = parseFloat(input.value)

    if (!isNaN(value)) {
      input.value = (
        value +
        sign * parseFloat(input.getAttribute('step'))
      ).toFixed(zeros)

      callback(name, input).finally(() => {
        if (button) button.classList.remove('is-loading')
      })
    }
  }

  patchQuantity(key, input, button) {
    return API.patch('/api/caminota-bom/' + this.form.dataset.slug + '/', {
      body: JSON.stringify({
        [key]: parseInt(input.value),
      }),
    })
      .then((response) => {
        if (response == null) return

        input.closest('.field').classList.remove('has-error')

        const update = document.querySelector(
          `[data-name="${input.dataset.updates}"]`
        )

        if (update && response.form[input.dataset.updates] != null) {
          update.innerHTML = response.form[input.dataset.updates]
          this.details.innerHTML = response.details
        }
      })
      .catch((e) => {
        input.closest('.field').classList.add('has-error')
      })
  }
}
