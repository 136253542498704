class Api {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      this.csrf = document.getElementsByName('csrfmiddlewaretoken')[0]?.value
      this.loader = document.querySelector('#loader')
    })
  }

  request(path, opts) {
    return fetch(path, opts).then((response) => {
      if (response.ok) return response

      return Promise.reject(response)
    })
  }

  post(path, opts) {
    return this.request(path, {
      ...opts,
      method: 'POST',
      headers: { 'X-CSRFToken': this.csrf },
    }).then((result) => result.json())
  }

  patch(path, opts) {
    return this.request(path, {
      ...opts,
      method: 'PATCH',
      headers: {
        'X-CSRFToken': this.csrf,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((result) => result.json())
  }

  get(path, opts) {
    return this.request(path, opts).then((result) => result.json())
  }
}

export default new Api()
