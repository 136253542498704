import Forms from './forms'
import Navbar from './navbar'
import '../sass/base.scss'

class APP {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      console.log('[CAMINOTA] Base online')

      this.forms = new Forms()
      this.navbar = new Navbar()
    })
  }
}

export default new APP()
